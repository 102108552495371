.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

h1 {
  margin-bottom: 20px;
  color: #ffffff;
}

.input {
  background-color: #665bc6;
  color: #ffffff;
}

.input::placeholder {
  color: #ffffff;
}

.input,
.login-button {
  border: 1px solid #665bc6;
  border-radius: 20px;
  padding: 12px;
  margin-bottom: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  background: linear-gradient(to right, #5270ff, #fc66c5);
}
