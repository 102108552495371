.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  margin-left: 550px;
}

.upload-text {
  color: #ffffff;
  font-size: 20px;
  margin-right: 30px;
}

.upload-input {
  border: 1px solid #665bc6;
  border-radius: 15px;
  padding: 15px;
}

.upload-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
}

.media-highlight {
  background-color: #032039;
  color: #000;
}

.preview-button {
  border: 1px solid #665bc6;
  border-radius: 15px;
  /* padding: 20px; */
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #0097fb;
  width: 140px;
}

.preview-btn-text {
  font-size: 19px;
}
