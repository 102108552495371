.details-text {
  color: #ffffff;
}

.details-upload-text {
  color: #ffffff;
  font-size: 20px;
  border: 1px solid #ffffff;
  padding-left: 10px;
  padding-bottom: 5px;
  border-radius: 15px;
}

.details-top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.frame {
  border-radius: 15px;
  background-color: #cfcece8e;
}

.details-mid {
  padding: 20px;
}

.frames {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.frames-container {
  display: flex;
  flex-wrap: nowrap;
}

.delete-btn-frame {
  width: 60px;
  border-radius: 10px;
  border-color: transparent;
  background-color: rgb(235, 96, 96);
  color: #ffffff;
  margin-top: 10px;
}
